import React, { useEffect, useState } from 'react';
import api from '../api';
import { Link } from 'react-router-dom';
import Search from './search';
import Add from './add';
import { Admin, useUser } from './admin';
import Menu from './menu';
import '../scss/main.scss'; 

function Start() {
    const [cases, setCases] = useState(false); 
    const [search, setSearch] = useState(''); 
    const [paging, setPaging] = useState(1);
    const user = useUser();

    useEffect(() => {
        api.support_cases().page(paging).embed().then(result => {
            let newCases = [];
            if(cases !== false) {
                newCases = cases.slice();
                delete newCases['_paging']
            } 
            newCases.push.apply(newCases, result)
            newCases['_paging'] = result['_paging']
            setCases(newCases)
        }).catch(() => {}) 
        //return () => Promise.reject('unmounted');
    }, [paging]) //[] uppdaterar inte support_cases

    function searchItem(cases) {
        return cases.filter((_case) => {
            return  _case.title.rendered.toLowerCase().includes(search.toLowerCase()) ||
            _case._embedded.author[0].name.toLowerCase().includes(search.toLowerCase()) ||
            _case._embedded['wp:term'].find(term => {
                return term[0].id === _case.support_case_status[0]
            })[0].name.toLowerCase().includes(search.toLowerCase()) ||
            _case._embedded['wp:term'].find(term => {
                return term[0] && term[0].id === _case.topics[0]
            })[0].name.toLowerCase().includes(search.toLowerCase()) 
        })
    }

    // function sortItems(cases) {
    //     var closed = cases.filter((_case) => (_case.support_case_status[0] === 19))
    //     var others = cases.filter((_case) => (_case.support_case_status[0] !== 19))
    //     return others.concat(closed)
    // }

    return ( 
        <Admin> 
            <Menu />
            <div className='content'>
            <Add />
            <div className='lista'>
                <h1>Issues</h1>
                <div className='wrapper-list'>   
                    <Search search={search} setSearch={setSearch}/>
                            <div className='categories-wrapper'>
                                <section>
                                    <div className="column">
                                        <div className='blue-column'>
                                            <h2>Topic</h2>
                                        </div>
                                    </div>
                                    <div className='column'>
                                        {user && (user.roles[0] === 'first_line' || user.roles[0] === 'support_worker') &&
                                            <div className='categories'>
                                                <h2>SLA</h2>
                                            </div> 
                                        }
                                    </div>
                                    <div className='column'>
                                        {user && (user.roles[0] === 'first_line' || user.roles[0] === 'support_worker') &&
                                            <div className='categories'>
                                                <h2>Customer</h2>
                                            </div> 
                                        }
                                    </div>
                                    <div className='column'>
                                        <div className='categories'>
                                            <h2>Status</h2>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            {!cases ? (
                                <h1>no cases</h1>
                            ) :searchItem(cases).map((_case, i) => 
                            <Link 
                                to={`/case/${_case.id}`} 
                                key={i} 
                                className='start-list'>
                                <div className='page-wrapper'>
                                    <section>
                                        <div className='column'>
                                            <div className='blue-column'>
                                                <h3>{_case.title.rendered}</h3>
                                                <p>{_case._embedded.author[0].name} | {_case.date.slice(0, 10)} {_case.date.slice(11, 16)}</p>
                                            </div>
                                        </div>
                                        <div className='column'>  
                                            {user && (user.roles[0] === 'first_line' || user.roles[0] === 'support_worker') &&  
                                                <div className='extra-info'>
                                                    <p>      
                                                        {(_case._embedded['wp:term'].find(term => {
                                                            return term[0] && term[0].id === _case.sla[0]
                                                        }) || [{name: '--'}] )[0].name}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                        <div className='column'>
                                            {user && (user.roles[0] === 'first_line' || user.roles[0] === 'support_worker') &&  
                                                <div className='extra-info'>
                                                    <p>
                                                        {(_case._embedded['wp:term'].find(term => {
                                                            return term[0] && term[0].id === _case.topics[0]
                                                        }) || [{name: '--'}] )[0].name}
                                                    </p> 
                                                </div>
                                            }
                                        </div>    
                                        <div className='column'>
                                            <div className='extra-info'>
                                                <p>
                                                    {_case._embedded['wp:term'].find(term => {
                                                        return term[0] && term[0].id === _case.support_case_status[0]
                                                    })[0].name} 
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </Link>
                            )}
                            {cases && cases._paging && paging+1 <= cases._paging.totalPages && (
                                <button 
                                className='btn-load-more'
                                type="button"
                                onClick={() => setPaging(paging + 1)}
                                >Load More</button>
                            )}
                    </div>
                </div>
            </div>
        </Admin>
    )        
}

export default Start;
import React, { useEffect, useState } from 'react';
import api from '../api';
import { useUser } from '../containers/admin';

function Sla(props) {
    const [selectedSla, setSelectedSla] = useState(props.status);
    const [allSlas, setAllSla] = useState([]);
    const user = useUser();

    useEffect(() => {
        api.sla().embed().then(result => setAllSla(result))
    }, [])

    if (allSlas.length === 0) {
        return null;
    }

    if (user && (user.roles[0] === 'customer_guest' || user.roles[0] === 'support_worker')) {
        return allSlas.find(term => {
            return term.id === props.status 
        }).name
    }

    return (
        user && user.roles[0] === 'first_line' &&
        <>
            <select value={selectedSla} onChange={event => {
                setSelectedSla(event.target.value)
                api.support_cases().id(props.id).update({
                    sla: selectedSla || 26,
                }).then(function(response) {
                    console.log(response.id);
                })
                }
            }>
                {allSlas.map((sla, i) => ( 
                    <option key={i} value={sla.id}>{sla.name}</option>
                ))}
            </select>
        </>
    )
}

export default Sla;
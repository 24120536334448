import React from 'react'; 
import api from '../api';

function Close(props) {
        const handleCloseClick = event => {
        event.preventDefault();
        api.support_cases().id(props.id).update({
            support_case_status: 19,
        }).then(function(response) {
            console.log(response.id);
            window.location.href='/';
        })
    }

    return(
        <div className='close'>
            <h2>Is the case solved?</h2>
            <button 
                className='btn-close'
                type="button"
                onClick={handleCloseClick}
            >Close case</button>
        </div> 
    )
}

export default Close; 
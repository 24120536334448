import React from 'react';
import './loader.scss';

function Loader() {
    return (
        <div className="cssload-container">
	        <ul className="cssload-flex-container">
                <li>
                    <span className="cssload-loading"></span>
                </li>
            </ul>
	    </div>
    )
}

export default Loader;
import React, { useEffect, useState } from 'react';
import api from '../api';
import { Redirect } from 'react-router-dom';

export function useUser() {
    const [user, setUser] = useState(null);
    const token = localStorage.getItem('token');
    
    if (token){
        api.setHeaders('Authorization', `Bearer ${token}`)
    }   

    useEffect(() => {
        api.users().me().context('edit').then(result => {
            setUser(result) 
          }).catch(error => {
            setUser(false)
            api.setHeaders('Authorization', null)
            localStorage.removeItem('token')
            return null
          })
    }, []) //uppdaterar inte me
    return user; 
}

export function Admin({children}) {
    const user = useUser();

    if (user === null) {
      return null
    } else if (user === false) {
      return <Redirect to='/login'/>
    } 
    return children;
}

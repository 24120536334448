import React, { useEffect, useState }from 'react';
import { withRouter } from 'react-router';
import api from '../api';
import Loader from '../partials/loader';
import { Link } from 'react-router-dom';
import Comment from '../comment';
import Close from '../partials/close';
import Status from '../partials/status';
import Sla from '../partials/sla';
import { Admin, useUser } from './admin';
import Menu from './menu';

function Case({match:{params:{id}}}) {
    const [_case, setCase] = useState(null);
    const [comments, setComments] = useState([]);
    const user = useUser();

    useEffect(() => {
        api.support_cases().id(id).embed().then(result => setCase(result))
        api.comments().post(id).embed().then(result => setComments(result))
    }, [id] )
    if (_case === null) {
        return <Loader />;
    } 
    
    return (
        <Admin>
            <Menu />
            <div className='wrapper-case'>
                <Link className='back' to='../'>Go back to list</Link>
                <div className='topic'>
                    <h1>{_case.title.rendered}</h1>
                    <h2>STATUS: {_case._embedded['wp:term'].find(term => {
                                return term[0].id === _case.support_case_status[0]
                                })[0].name}</h2>
                </div>
                <div className='issue'>
                    <div dangerouslySetInnerHTML={{__html: _case.content.rendered}}/>
                </div>
                <div className='list-comment'>
                    <ul id='comments'>
                        {comments.reverse().map((comment, i) => (
                            <div className='com' key={i}>
                                <h3>{comment.author_name}: {comment.date.slice(0, 10)} {comment.date.slice(11, 16)}</h3>
                                <p dangerouslySetInnerHTML={{__html: comment.content.rendered}}/>
                            </div>
                        ))}
                    </ul>
                </div>
                <h1 className="text">Add Comment</h1>
                <Comment id={id}/>
                <Close id={id} />
                <div className='info'>
                    {user && (user.roles[0] === 'first_line' || 'support_worker') &&
                        <p>STATUS:  
                            <Status id={id} status={_case.support_case_status[0]}/></p>
                    }
                    {user && (user.roles[0] === 'first_line' || user.roles[0] === 'support_worker') &&
                        <p>SLA:     
                            <Sla id={id} status={_case.sla[0]}/></p>
                    }
                    {user && (user.roles[0] === 'first_line' || user.roles[0] === 'support_worker') &&
                        <p>Client:  {_case._embedded['wp:term'].find(term => {
                                return term[0].id === _case.topics[0]
                                })[0].name}</p>
                    }
                    <p>ISSUER:      {_case._embedded.author[0].name}</p>
                    <p>PUBLISHED:   {_case.date.slice(0, 10)} {_case.date.slice(11, 16)}</p>
                </div>
            </div>
        </Admin>
    )
}

export default withRouter(Case);
import React from 'react';
import './scss/main.scss';
import Login from './containers/login';
import Case from './containers/case';
import Start from './containers/start';
import { BrowserRouter, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Route path="/"    exact render={(props)=><Start {...props}/>} />
      <Route path="/login/"    render={(props)=><Login {...props}/>} />
      <Route path="/case/:id/" render={(props)=><Case  {...props}/>} />
    </BrowserRouter>
  );
}

export default App;
import React, { useState } from 'react';
import api from '../api';
import { Redirect } from 'react-router-dom';
import '../scss/main.scss';

function Login() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    if (isLoggedIn) {
        return <Redirect to='/'/> 
    }
    return (
        <div className='wrapper-login'>
            <div className="logo"></div>
            <div>
                <form 
                    onSubmit={event => {
                    event.preventDefault()
                    api.jwt().create({username, password})
                    .then(result => {
                        localStorage.setItem('token', result.token)
                        console.log(result)
                        localStorage.setItem('username', result.user_name)
                        api.setHeaders('Authorization', `Bearer ${result.token}`)
                        setIsLoggedIn(true)
                    }).catch(error => console.log(error))
                    return false;
                }}>
                    <h1>Login</h1>
                    <div className='enter'>
                        <label>Username</label>
                        <input 
                            className='input'
                            type='text'
                            onChange={event => setUsername(event.target.value)}
                            value={username} />
                        <label>Password</label>
                        <input
                            className='input'
                            type='password'
                            onChange={event => setPassword(event.target.value)}
                            value={password} />
                        <input 
                            className='btn-login'
                            type='submit'
                            value='Get started' />
                    </div>
                </form>
            </div>
        </div>
    ) 
}

export default Login;
import React from 'react'; 
import { Link } from 'react-router-dom';
import { useUser } from './admin';

function Menu() {
    const user = useUser();

    return(
        <header className='wrapper-menu'>
            <Link to='/' className='logo'></Link>
            {<h3>Welcome {user && user.name}</h3>}
            <a 
                className='btn-logout'
                href='/login' 
                onClick={() => {
                    localStorage.removeItem('token')
                    window.reload()
                }}>Logout</a>
      </header>
    ) 
}

export default Menu;
import React, { useState } from 'react';
import api from './api';

function Comment(props) {
    const [newComment, setNewComment] = useState('');
    
    return (
        <form
            className='addComment' 
            onSubmit = {event => {
            event.preventDefault()
            api.comments().create({
                post: props.id,
                content: newComment,
                status: 'approved'
            }).then(function(response) {
                console.log(response.id);
                window.location.reload();
            })
        }}>
            <h2>Comment this post:</h2>
            <textarea onChange={event => setNewComment(event.target.value)} />
            <input
                className='btn-publish'
                type='submit'
                value='Publish comment' />
        </form>
    )
}
export default Comment;
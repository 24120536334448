import WPAPI from 'wpapi';

export const endpoint = process.env.REACT_APP_SUPPORT_DEV_API_URL || 'https://support.businessandemotions.se/wordpress/wp-json';
const api = new WPAPI({endpoint});

api.support_cases   = api.registerRoute('wp/v2', '/support_cases/(?P<id>\\d+)');
api.jwt             = api.registerRoute('jwt-auth/v1', '/token');
api.customers       = api.registerRoute('wp/v2', '/topics');
api.statuses        = api.registerRoute('wp/v2', '/support_case_status');
api.sla             = api.registerRoute('wp/v2', '/sla')

export default api;
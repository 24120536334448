import React from "react";

function Search(props) {
    const handleInput = (event) => { 
        props.setSearch(event.target.value); 
    }

    return (
        <input 
            className='search'
            onChange={handleInput}
            value={props.search}
            placeholder="Search"
        />
    );             
}

export default Search;
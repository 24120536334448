import React,{ useEffect, useState } from 'react';
import api from '../api';
import { useUser } from './admin';

function Add(props) {
    const [topic, setTopic] = useState('');
    const [issue, setIssue] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [customers, setCustomers] = useState([]);
    const [selectedSla, setSelectedSla] = useState(25);
    const [slas, setSla] = useState([]);
    const user = useUser();

    useEffect(() => {
        api.support_cases(props.id).embed().then(result => setTopic(result))
        api.support_cases(props.id).embed().then(result => setIssue(result))
        api.customers().embed().then(result => setCustomers(result))
        api.sla().embed().then(result => setSla(result))
    }, [props.id] )
    if(topic && issue && customers && slas === '') {
        return null;
    }

    return (
        <form
            onSubmit = {event => {
            event.preventDefault()
            api.support_cases().create({
                title: topic,
                content: issue,
                topics: selectedCustomer || user.user_customer || 27,
                support_case_status: 20,
                sla: selectedSla,
                status: 'publish'
            }).then(function(response) {
                console.log(response.id);
                window.location.reload();
            })
        }}>
        <h1>Add Case</h1>
        <div className='wrapper-add'>
            <div className='add-form'>
                <div className='box'>
                    {user && (user.roles[0] === 'first_line' || user.roles[0] === 'support_worker') &&
                    <div className='dropdown'>
                        <h3>Choose Customer:</h3>
                        <select onChange={event => setSelectedCustomer(event.target.value)} >
                            {customers.map((customer, i) => ( 
                                <option key={i} value={customer.id}>{customer.name}</option>
                            ))}
                        </select> 
                    </div>
                    } 
                    {user && user.roles[0] === 'first_line' &&
                    <div className='dropdown'>
                        <h3>Choose sla:</h3>                      
                        <select onChange={event => setSelectedSla(event.target.value)} value={selectedSla}>
                            {slas.map((sla, i) => (
                                <option key={i} value={sla.id}>{sla.name}</option>
                            ))}
                        </select>
                    </div>
                    }
                </div>
                <div className='add-write'>
                    <h2>Topic:</h2> 
                    <input onChange={event => setTopic(event.target.value)} />
                    <h2>Describe your issue:</h2>
                    <textarea 
                        className='textarea'
                        onChange={event => setIssue(event.target.value)} />
                </div>
                <input
                    className='btn-publish'
                    type='submit'
                    value='Publish' />
            </div>
        </div>
        </form>
    )
}

export default Add;
import React, { useEffect, useState } from 'react';
import api from '../api';
import { useUser } from '../containers/admin';

function Status(props) {
    const [selectedStatus, setSelectedStatus] = useState(props.status);
    const [allStatus, setAllStatus] = useState([]);
    const user = useUser();

    useEffect(() => {
        api.statuses().embed().then(result => setAllStatus(result))
    }, [] )

    if (allStatus.length === 0) {
        return null;
    }

    if (user && user.roles[0] === 'customer_guest') {
        return allStatus.find(term => {
            return term.id === props.status 
            }).name
        }

    return (
        user && (user.roles[0] === 'first_line' || user.roles[0] === 'support_worker') &&
        <>
            <select value={selectedStatus} onChange={event => {
                setSelectedStatus(event.target.value)
                api.support_cases().id(props.id).update({
                    support_case_status: event.target.value || 21,
                }).then(function(response) {
                    console.log(response.id);
                })
                }
            }>
                {allStatus.map((stat, i) => ( 
                    <option key={i} value={stat.id}>{stat.name}</option>
                ))}
            </select>
        </>    
    ) 
}

export default Status;